<template>
  <validation-provider
    ref="fileInput"
    v-slot="{ errors }"
    :rules="rules"
    :skip-if-empty="true"
    :name="name"
  >
    <form ref="formPicture" class="media-form">
      <v-img contain :src="image" :height="height" />
      <div class="upload">
        <v-btn
          text
          dense
          :color="options.bgColor"
          :class="{ shadow: options.shadow }"
          small
          fab
          @click="$refs.nativeFile.click()"
        >
          <v-icon :color="options.textColor">
            mdi-cloud-upload
          </v-icon>
          <input
            ref="nativeFile"
            style="display: none;"
            type="file"
            @change="filePick($event)"
          />
        </v-btn>

        <v-btn
          v-if="value && canDelete"
          text
          dense
          :color="options.bgColor"
          :class="{ shadow: options.shadow }"
          class="ml-3"
          small
          fab
          @click="clearFile"
        >
          <v-icon :color="options.textColor">
            mdi-delete
          </v-icon>
        </v-btn>
      </div>
    </form>

    <div transition="scroll-y-transition">
      <div
        v-if="errors.length > 0"
        class="v-messages theme--light error--text mt-2"
        role="alert"
      >
        <div class="v-messages__wrapper">
          <div class="v-messages__message">
            {{ errors[0] }}
          </div>
        </div>
      </div>
    </div>
  </validation-provider>
</template>

<script>
import { onMounted, reactive, ref, toRefs, watch } from '@vue/composition-api';

import mediaComposition from '@/compositions/media';

import { ValidationProvider, extend } from 'vee-validate';

import { size, image, required } from 'vee-validate/dist/rules.js';

extend('required', required);

extend('size', size);

extend('image', {
  ...image,
  message: 'File must be an image'
});

const STUB_MEDIA = '/img/empty-media.svg';
const DEFAULT_HEIGHT = 120;
const HEIGHT_WHEN_SELECTED = 240;

export default {
  name: 'MediaInput',
  components: {
    ValidationProvider
  },
  props: {
    rules: {
      type: String,
      default: 'size:4000'
    },
    name: {
      type: String,
      default: 'File'
    },
    value: {
      type: File,
      default: null
    },
    id: {
      type: String,
      default: null
    }
  },
  setup(props, { emit }) {
    const options = {
      bgColor: 'transparent',
      textColor: 'white',
      showTitle: false,
      shadow: true
    };

    const fileInput = ref(null);
    const nativeFile = ref(null);

    const state = reactive({
      image: STUB_MEDIA,
      error: [],
      height: DEFAULT_HEIGHT,
      canDelete: true
    });

    const { getImageById } = mediaComposition();

    const clearFile = () => {
      nativeFile.value.value = '';
      state.error = [];
      state.image = STUB_MEDIA;
      state.height = DEFAULT_HEIGHT;
      state.canDelete = false;
      setImagePreview(props.id);
      emit('input', null);
    };

    const filePick = async e => {
      fileInput.value.errors = [];
      fileInput.value.validate(e.target.files[0]).then(({ valid }) => {
        if (!valid) return;

        const [file] = e.target.files;
        const isImage = file.type.includes('image');

        if (isImage) {
          state.image = URL.createObjectURL(file);
        } else {
          state.image = '/img/non-image-media.svg';
        }

        state.canDelete = true;
        state.height = HEIGHT_WHEN_SELECTED;

        emit('input', file);
      });
    };

    const setImagePreview = mediaId => {
      if (mediaId) {
        state.height = HEIGHT_WHEN_SELECTED;
        state.image = getImageById(mediaId);
      }
    };

    watch(
      () => props.id,
      value => {
        if (value) {
          setImagePreview(value);
        }
      }
    );

    onMounted(() => {
      setImagePreview(props.id);
    });

    return {
      ...toRefs(state),
      clearFile,
      filePick,
      options,
      fileInput,
      nativeFile
    };
  }
};
</script>

<style lang="scss" scoped>
.media-form {
  position: relative;
  background-color: #dbdbdb;
  background-position: center;
  min-height: 120px;
}

.upload {
  position: absolute;
  top: 15px;
  right: 15px;
}

.shadow {
  background-color: rgba(0, 0, 0, 0.44) !important;
  border-color: rgba(0, 0, 0, 0.44) !important;
}
</style>
